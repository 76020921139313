export enum JobStatus {
    "queued" = 'queued',
    "running" = "running",
    "completed" = "completed",
    "failed" = "failed"
}

export class Job {
    id: string;
    status: JobStatus;
    errorDetail?: any;
}
import CourseService from "./CourseService";

export default class LessonDetailCtrl {
    static $resolve = {
        course: ($stateParams, CourseService: CourseService) => CourseService.getCourseInfo($stateParams.courseId),
        lesson: ($stateParams, CourseService: CourseService) => CourseService.getLessonInfo($stateParams.courseId, $stateParams.lessonId),
    };

    constructor(public course, public lesson, public AuthService, private CourseService: CourseService) {
        this.course = course;
        this.lesson = lesson;
    }
}

import * as angular from "angular";
import TenantsCtrl from "./TenantsCtrl";
import TenantsCoursesCtrl from "./TenantsCoursesCtrl";

export default angular.module("inphinity.app.admin.tenants", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("admin-tenants", {
                url: "/admin/tenants?id",
                template: require("./tenants.html").default,
                controllerAs: "ctrl",
                controller: TenantsCtrl,
            })
            .state("admin-tenants-courses", {
                url: "/admin/userCourses?userId",
                template: require("./courses.html").default,
                controllerAs: "ctrl",
                controller: TenantsCoursesCtrl,
                resolve: TenantsCoursesCtrl.$resolve
            })
    })
    .name;

import * as angular from "angular";
import TemplateService from "./TemplateService";
import TemplateInfoCtrl from "./TemplateDetailCtrl";
import TemplateListCtrl from "./TemplateListCtrl";
import TemplateListItemCtrl from "./TemplateListItemCtrl";
import "./store.css";

export default angular.module("inphinity.app.templates", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("template-detail", {
                url: "/store/:id/detail?sheetId",
                templateUrl: "html/template-detail.html",
                controller: TemplateInfoCtrl,
                controllerAs: "ctrl",
                resolve: TemplateInfoCtrl.$resolve
            })
            .state("template-list", {
                url: "/store?company&name&categories&type&text&sort",
                templateUrl: "html/template-list.html",
                controllerAs: "ctrl",
                controller: TemplateListCtrl,
                resolve: TemplateListCtrl.$resolve,
                reloadOnSearch: false
            })
    })
    .directive("mspTemplateListItem", function () {
        return {
            restrict: "E",
            templateUrl: "html/TemplateListItem.html",
            scope: {
                app: "=",
                demoUrl: "=",
                search: "=",
                onOpen: "&",
                currency: "="
            },
            controller: TemplateListItemCtrl,
            controllerAs: "ctrl",
            link(scope) {
                scope.Math = Math;
            }
        }
    })
    .service("TemplateService", TemplateService)
    .name;

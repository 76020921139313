import * as angular from "angular";
import "./courses.css";
import CourseService from "./CourseService";
import CourseListCtrl from "./CourseListCtrl";
import CourseDetailCtrl from "./CourseDetailCtrl";
import LessonDetailCtrl from "./LessonDetailCtrl";

export default angular.module("inphinity.app.courses", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("courses", {
                url: "/courses",
                template: require("./courses.html").default,
                controllerAs: "ctrl",
                controller: CourseListCtrl,
                resolve: CourseListCtrl.$resolve
            })
            .state("course-detail", {
                url: "/course/:courseId",
                template: require("./course-detail.html").default,
                controller: CourseDetailCtrl,
                controllerAs: "ctrl",
                resolve: CourseDetailCtrl.$resolve
            })
            .state("lesson-detail", {
                url: "/course/:courseId/:lessonId",
                template: require("./lesson-detail.html").default,
                controller: LessonDetailCtrl,
                controllerAs: "ctrl",
                resolve: LessonDetailCtrl.$resolve
            })
    })
    .service("CourseService", CourseService)
    .name;

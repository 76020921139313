import CourseService from "./CourseService";

export default class CourseDetailCtrl {
    static $resolve = {
        course: ($stateParams, CourseService: CourseService) => CourseService.getCourseInfo($stateParams.courseId),
        courses: (ApiService) => ApiService.get("/api/courses").then(r => r.data),
        opened: ($stateParams, CourseService: CourseService) => CourseService.setCourseOpened($stateParams.courseId),
    };

    public relatedCoursesDetails = [];
    public link;

    constructor(public course, public AuthService, private CourseService: CourseService) {
        this.orderLessons();
        this.course = course;
        this.loadRelatedCourses();
    }

    loadRelatedCourses() {
        this.course.relatedCourses.forEach(courseId => {
            this.CourseService.getCourseInfo(courseId).then(courseDetail => {
                this.relatedCoursesDetails.push(courseDetail);
            });
        });
    }

    async setLessonRead(lessonId) {
        await this.CourseService.setLessonRead(this.course.courseId, lessonId);
    }

    async setLessonUnread(lessonId) {
        await this.CourseService.setLessonUnread(this.course.courseId, lessonId);
        await this.load();
    }

    share() {
        this.link = window.location.protocol + "//" + window.location.host + "/share/course/" + this.course.courseId;
        navigator.clipboard.writeText(this.link);
    }

    async load() {
        this.course = await this.CourseService.getCourseInfo(this.course.courseId)
        this.orderLessons();
    }

    orderLessons() {
        this.course.lessons = this.course.lessons.sort((a, b) => a.order - b.order);
    }
}

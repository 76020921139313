import CourseService from "../../courses/CourseService"

export default class TenantsCoursesCtrl {
    static $resolve = {
        courses: ($stateParams, ApiService) => ApiService.get(`/api/courses/admin/${$stateParams.userId}`).then(r => r.data)
    };

    private userId;

    constructor($stateParams, public courses, public AuthService, private CourseService: CourseService, private ApiService) {
        this.orderCoursesAndLessons();
        this.userId = $stateParams.userId;
    }

    async setCourseCompleted(courseId) {
        await this.CourseService.setCourseCompleted(this.userId, courseId);
        await this.load();
    }

    async setCourseUncompleted(courseId) {
        await this.CourseService.setCourseUncompleted(this.userId, courseId);
        await this.load();
    }

    async setLessonRead(courseId, lessonId) {
        await this.CourseService.setLessonReadAdmin(courseId, lessonId, this.userId);
        await this.load();
    }

    async setLessonUnread(courseId, lessonId) {
        await this.CourseService.setLessonUnreadAdmin(courseId, lessonId, this.userId);
        await this.load();
    }

    async load() {
        this.courses = (await this.ApiService.get("/api/courses/admin/" + this.userId)).data
        this.orderCoursesAndLessons();
    }

    orderCoursesAndLessons() {
        this.courses.forEach(course => {course.lessons.sort((a, b) => a.order - b.order)});
        this.courses = this.courses.sort((a, b) => a.order - b.order);
    }
}
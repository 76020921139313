const BANNER_COUNT = 3;
import ApiService from "../api/ApiService";
import DialogService from "../dialogs/DialogService";
import TemplateService from "./TemplateService";

export default class TemplateListCtrl {
    public demoUrl;
    public search = {author: {company: '', name: ''}, categories: '', type: '', $: ''};
    public sort;
    public bannerNumber;
    public currency = '€';
    public categoryTree;
    public private = {};
    private selectedCategories = [];
    private categoryParamsCache = {};
    public link;
    public deployedAppId;
    public expanded = false;
    public state;
    public selectedCategoriesGeneral = [];
    public selectedCategoriesTeams = [];
    public selectedCategoriesIndustries = [];
    public sortBy = "";

    static $resolve = {
        apps: (ApiService) => ApiService.get("/api/templates").then(r => r.data),
    };

    public categoryTreeDefaults = {
        "Teams": [
            "Compliance & Legal",
            "Finance",
            "Human Resources",
            "IT & Management Tools",
            "Marketing",
            "Operations",
            "Sales"
        ],
        "Industries": [
            "Automotive",
            "Financial Services",
            "Government",
            "Healthcare",
            "Manufacturing",
            "Retail & Distribution",
        ],
    }

    constructor(public apps, config, private AppService, private DialogService: DialogService, $scope, $state, $interval, private ApiService: ApiService, public AuthService, private TemplateService: TemplateService, private SettingsService) {
        this.demoUrl = config.qlik.demoUrl;
        this.apps = apps;
        this.state = $state;

        if (config.currency) {
            this.currency = config.currency;
        }

        let updateSearch = () => {
            this.search.author.company = $state.params.company;
            this.search.author.name = $state.params.name;
            this.search.categories = $state.params.categories;
            this.search.type = $state.params.type;
            this.search.$ = $state.params.text;
            this.sort = $state.params.sort;
            this.selectedCategories = [];
            this.categoryParamsCache = {};
            if ($state.params.categories) {
                this.selectedCategories = $state.params.categories.split(",");
                if (this.selectedCategories.length > 1) {
                    this.search.categories = '';
                }
            }
            this.selectedCategoriesGeneral = this.selectedCategories.filter(category => !this.categoryTreeDefaults.Teams.includes(category) && !this.categoryTreeDefaults.Industries.includes(category));
            this.selectedCategoriesTeams = this.categoryTreeDefaults.Teams.filter(category => this.selectedCategories.includes(category));
            this.selectedCategoriesIndustries = this.categoryTreeDefaults.Industries.filter(industry => this.selectedCategories.includes(industry));
            if (!this.sort) this.sort = ["featured","-creationDate"];
            this.changeBanner();
        }
        this.bannerNumber = 0;

        if (config.templatesCategoryTree) {
            this.categoryTree = config.templatesCategoryTree;
        } else {
            this.categoryTree = this.categoryTreeDefaults;
        }

        updateSearch();
        $scope.$watchCollection(() => $state.params, updateSearch);
        $scope.$watch("ctrl.search.$", () => this.categoryParamsCache = {});
        this.changeBanner();
        this.searchFn = this.searchFn.bind(this);
    }

    changeBanner() {
        this.bannerNumber = 1;
    }

    openApp(template) {
        window.open(template.apps[0].url);
    }

    isCategoryItemActive(item) {
        return this.selectedCategories.indexOf(item) >= 0;
    }

    categorySelection(item) {
        let categories = [];
        if (this.selectedCategories.includes(item)) {
            categories = this.selectedCategories.filter(category => category !== item);
        } else {
            categories = [...this.selectedCategories, item];
        }
        const params = {
            categories: categories.join(","),
            text: this.search.$
        };
        this.state.go('template-list', params);
    }

    toggleIcon() {
        this.expanded = !this.expanded;
    };

    searchFn(item) {
        const searchTerm = (this.search.$ || "").toLowerCase();
        const itemInfo = [item.name, item.author.name, item.author.company, item.info, item.categories.join(" "), item.highlights.join(" "), item.details, item.features, item.infoHtml, item.highlightsHtml].join(" ").toLowerCase();

        const categoriesGeneralMatch = this.selectedCategoriesGeneral.every(c => item.categories.indexOf(c) >= 0);
        const categoriesCategoryMatch = this.selectedCategoriesTeams.some(c => item.categories.indexOf(c) >= 0);
        const categoriesIndustryMatch = this.selectedCategoriesIndustries.some(c => item.categories.indexOf(c) >= 0);
        const searchTermMatch = itemInfo.includes(searchTerm);
        
        if (this.selectedCategoriesTeams.length > 0 && this.selectedCategoriesIndustries.length > 0) {
            return categoriesGeneralMatch && categoriesCategoryMatch && categoriesIndustryMatch && searchTermMatch;
        }
        else if (this.selectedCategoriesTeams.length > 0) {
            return categoriesGeneralMatch && categoriesCategoryMatch && searchTermMatch;
        }
        else if (this.selectedCategoriesIndustries.length > 0) {
            return categoriesGeneralMatch && categoriesIndustryMatch && searchTermMatch;
        }
        return categoriesGeneralMatch && searchTermMatch;
    }

    download(app) {
        this.ApiService.postForm("/api/templates/" + app.id + "/download/" + app.name, null);
    }

    downloadLogin() {
        this.AuthService.login();
    }

    loggedIn() {
        return this.AuthService.isLoggedIn();
    }

    share(app) {
        this.link = window.location.protocol + "//" + window.location.host + "/share/store/" + app.id;
        navigator.clipboard.writeText(this.link);
        void this.DialogService.share(this.link);
    }

    async deployDemo(app) {
        try {
            this.DialogService.loading("Activating...");
            this.deployedAppId = await this.TemplateService.deployDemoTemplate(app.id, app.name);
            app = await this.TemplateService.getTemplateInfo(app.id);
            this.apps[this.apps.findIndex(existingApp => existingApp.id === app.id)] = app;
            this.DialogService.success("App activated!");
        } catch (error) {
            this.DialogService.error("Error activating app.");
        }
    }

    activateLogin(app) {
        this.AuthService.login("#!/store/" + app.id + "/detail");
    }
    
    async changeFavouriteFlag(app) {
        var fav = [{
            userId: this.AuthService.getUser().sub,
            appInstanceId: app.apps[0].id,
            favourite: app.favourite ? 0 : 1
        }];
        app.favourite = !app.favourite;
        app.favourite ? app.categories.push("Favourite") : app.categories.splice(app.categories.indexOf("Favourite"), 1);
        await this.SettingsService.updateFavourites(fav);
        await this.loadApps();
    }

    async loadApps() {
        this.apps = await this.AppService.getApps().filter(app => !(app.subscription.appInstanceId == null));
    }

    sortMark(sortBy) {
        this.sortBy = sortBy;
    }
}
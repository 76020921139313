import * as angular from "angular";
import FaqCtrl from "./FaqCtrl";

export default angular.module("inphinity.app.faq", [])
    .config(function routes($stateProvider) {
        $stateProvider
            .state("faq", {
                url: "/faq?area&category&number",
                template: require("./faq.html").default,
                controllerAs: "ctrl",
                controller: FaqCtrl,
                resolve: FaqCtrl.$resolve
            })
    })
    .name;

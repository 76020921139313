export default class CourseService {
    constructor(private ApiService) {
    }

    getCourseInfo(courseId: string) {
        return this.ApiService.get("/api/courses/" + courseId).then(r => r.data);
    }

    getLessonInfo(courseId: string, lessonId: string) {
        return this.ApiService.get("/api/courses/" + courseId + "/lesson/" + lessonId).then(r => r.data);
    }

    setLessonRead(courseId: string, lessonId: string) {
        return this.ApiService.post("/api/courses/" + courseId + "/lesson/" + lessonId + "/read").then(r => r.data);
    }

    setLessonUnread(courseId: string, lessonId: string) {
        return this.ApiService.delete("/api/courses/" + courseId + "/lesson/" + lessonId + "/read").then(r => r.data);
    }

    setCourseOpened(courseId: string) {
        return this.ApiService.post("/api/courses/" + courseId + "/opened").then(r => r.data);
    }

    setCourseCompleted(userId: string, courseId: string) {
        return this.ApiService.post("/api/courses/" + courseId + "/completed/" + userId).then(r => r.data);
    }

    setCourseUncompleted(userId: string, courseId: string) {
        return this.ApiService.delete("/api/courses/" + courseId + "/completed/" + userId).then(r => r.data);
    }

    setLessonReadAdmin(courseId: string, lessonId: string, userId: string) {
        return this.ApiService.post("/api/courses/" + courseId + "/lesson/" + lessonId + "/user/" + userId + "/read").then(r => r.data);
    }

    setLessonUnreadAdmin(courseId: string, lessonId: string, userId?: string) {
        return this.ApiService.delete("/api/courses/" + courseId + "/lesson/" + lessonId + "/user/" + userId + "/read").then(r => r.data);
    }
}

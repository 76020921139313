import ApiService from "../api/ApiService";
import CourseService from "./CourseService";

export default class CourseListCtrl {

    static $resolve = {
        courses: (ApiService) => ApiService.get("/api/courses").then(r => r.data),
    };

    constructor(public courses, public AuthService) {
        this.courses = courses.sort((a, b) => a.order - b.order);
    }

    loggedIn() {
        return this.AuthService.isLoggedIn();
    }

    openLogin(courseId) {
        this.AuthService.login("#!/courses/" + courseId);
    }
}